import { Fragment } from "react";
import { useSelector } from "react-redux";
import Card from "../UI/Card";
import Button from "../UI/Button";

const WelcomePage = (props) => {
  // Global settings
  const languageValue = useSelector((state) => state.languageValue);

  // Function to go to Stage Page for Visual Acuity Test
  const goToStagePageVAT = () => {
    props.setShowWelcomePage(false);
    props.setShowStagePageVAT(true);
  };

  return (
    // Returning info text on welcome page
    <Fragment>
      <main>
        <h2 className="page-title">
          {languageValue === "svenska" && "तैयार हो जाओ, अभी परीक्षण करो!"}
          {languageValue === "english" && "Get Set, Go!"}
        </h2>
        <div className="row-col-layout">
          <div className="column">
            <img
              className="vision-test-img"
              src={process.env.PUBLIC_URL + "/images/vision-test-img.jpg"}
              alt="girl taking vision test"
            />
          </div>
          <div className="column">
            <Card>
              {languageValue === "svenska" && (
                <div className="text">
                  <p>
                    अपनी आंखों के समन्वय को प्रशिक्षित करने में सक्षम होने के लिए, यह महत्वपूर्ण है कि आपके पास कार्यशील दृष्टि हो।
                  </p>
                  <p>
                    हमारे नेत्र प्रशिक्षण के लिए, वे दो परीक्षण प्रासंगिक हैं: दृश्य तीक्ष्णता और दृष्टिवैषम्य।
                  </p>
                  <p>
                   परीक्षण मोबाइल स्क्रीन से 40 सेमी या कंप्यूटर स्क्रीन से 1 मीटर की दूरी पर किया जाना चाहिए।
                  </p>
                  <p>
                    यदि आप दूर दृष्टि के लिए चश्मा या कॉन्टैक्ट लेंस या प्रगतिशील चश्मा पहनते हैं, तो आपको परीक्षण के दौरान उन्हें चालू रखना चाहिए।
                  </p>
                  <p>
                    यह परीक्षण किसी ऑप्टिशियन द्वारा की गई संपूर्ण नेत्र जांच का स्थान नहीं लेता है।
                  </p>
                  <p>
                    यदि परीक्षण से पता चलता है कि आपको आंखों की जांच की आवश्यकता है, तो हम आपको किसी ऑप्टिशियन से दृष्टि जांच कराने की सलाह देते हैं।
                  </p>
                </div>
              )}
              {languageValue === "english" && (
                <div className="text">
                    <p>Two critical tests for vision check & eye training: visual
                    acuity & astigmatism.
                    </p>
                    <p><br/>
                	Mobile Phone:  40 cms distance
                    <br/>
                    Laptop or PC: 100 cms distance
                 	</p>
                    <p><br/>
                    This test does not replace a complete eye examination
                    performed by an optician.
                    </p>
                </div>
              )}
              <div className="row">
                <Button
                  type="button"
                  onClick={(ev) => {
                    ev.preventDefault();
                    goToStagePageVAT();
                  }}
                >
                  {" "}
                  {languageValue === "svenska" && "शुरू"}
                  {languageValue === "english" && "START"}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default WelcomePage;
